import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/free-solid-svg-icons'
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import Alerts from '../helpers/Alerts';
import { IsValidToken } from "../../services/AuthenticationService";
import { useParams } from 'react-router-dom';
import { onlyNumbers } from '../helpers/common';
import Bonus from '../Bonus';

export const EwalletMethods = () => {
    let MAXLIMIT = process.env.REACT_APP_MaxLimit;
    const { id } = useParams();
    const [arrayRegularDeposits, setRegularDeposits] = useState(JSON.parse(localStorage.getItem('ArrayRegularMethods')).filter(function (element) { return element.DepositID == id; })[0]);
    const navigate = useNavigate();
    const [isProcessing, setIsProcessing] = useState(false);

    useEffect(() => {
        IsValidToken(localStorage.getItem('Token')).then()
            .catch(function (error) {
                navigate('/expired/');
            })
        if (arrayRegularDeposits == undefined) {
            navigate('/deposits/');
        }
    }, [])

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!isProcessing) {
            var data = new FormData(event.target);
            let { ewamount } = Object.fromEntries(data.entries());
            if (ewamount != '') {
                setIsProcessing(true);
                //await InitiateTransaction(ewamount);
                setIsProcessing(false);
                
            }
            else {
                Alerts.ShowAlert('Warning', 'Amount required', 'warning');
            }
        }
    }

    return (
        <div >
            <form onSubmit={handleSubmit}>
            <div className="row m-4">
                <span className="icon-single-left txt-primary">
                    <img style={{ width: "100px", height: "100px" }} src={"/svg/" + arrayRegularDeposits.ImageName + ".svg"} /> Deposit using {id}
                </span>
            </div>
            <div className="row">
                <div className="col-md-12 col-sm-12">
                    <div className="row">
                        <div className="col-md-12 clear row-12" ng-if="isSecureIdVisible">
                            <div className="col-md-12 col-sm-12 col-xs-12">
                                <label for="secureid" className="col-md-offset-3 col-md-2  col-sm-offset-3 col-sm-2 col-xs-12">SecureID:</label>
                                <div className="col-md-7 col-sm-7 col-xs-12">
                                    <input type="password" className="form-control" name="secureid" ng-model="ewForm.Secureid"
                                        aria-label="secure id" placeholder="{{ 'SecureID' | translate }}" required/>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 clear row-12" ng-if="isAccountVisible">
                            <div className="col-md-12 col-sm-12 col-xs-12">
                                <label for="secureid" className="col-md-offset-3 col-md-2  col-sm-offset-3 col-sm-2 col-xs-12">Account:</label>
                                <div className="col-md-7 col-sm-7 col-xs-12">
                                    <input type="text" className="form-control" name="accountoremail" ng-model="ewForm.AccountOrEmail"
                                            aria-label="account or email" placeholder="{{ 'ChooseAccount' | translate }}" required/>
                               </div>
                            </div>
                        </div>
                        <div className="col-md-12 m3">
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-currency" id="lblCurrency">USD</span>
                                    </div>
                                    <input type="number" onKeyPress={(event) => onlyNumbers(event)}  name="ewamount" className="form-control" aria-label="Amount (to the nearest dollar)" id="amount" max={arrayRegularDeposits.MaxDeposit == 0 ? MAXLIMIT : arrayRegularDeposits.MaxDeposit} min={arrayRegularDeposits.MinDeposit == 0 ? 1 : arrayRegularDeposits.MinDeposit} aria-describedby="lblCurrency" placeholder="Amount" required="" />
                            </div>

                            <div className="d-flex flex-row ml-2 limit-text-center mb-4 ">
                                Deposit Limits: Minimum {arrayRegularDeposits.MinDeposit == 0 ? 1 : arrayRegularDeposits.MinDeposit} USD Maximum {arrayRegularDeposits.MaxDeposit == 0 ? 'No Limit' : arrayRegularDeposits.MaxDeposit + ' USD'}
                                </div>
                                <Bonus />
                        </div>
                    </div>
                </div>
               
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="alert alert-danger text-center" role="alert">
                        Only Bitcoin can be sent to the generated address from the selected network.  Deposits of other crypto currencies or networks will be lost.
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12 col-sm-12 col-xs-12 " >

                </div>
                <div className="col-md-12 mb-3-small mt-4">
                    <button type="button" className="btn btn-secondary btn-gray btn-back-order mb-4 d-none d-md-block" onClick={() => navigate('/deposits/')}>BACK</button>
                    <button type="submit" className="btn btn-deposit btn-deposit-order mb-4 submit">DEPOSIT</button>
                </div>
            </div>


            </form >
        </div>
        
    );
}