import { useEffect, useState } from 'react';
import { useNavigate, Link } from "react-router-dom";
import Alerts from '../helpers/Alerts';
import { IsValidToken } from "../../services/AuthenticationService";
import { GetPreferredLimits } from "../../services/DepositService"

export const PriorityMethods = () => {
    const navigate = useNavigate();
    const [arrayPriorityDeposits, setArrayPriorityDeposits] = useState([]);

    useEffect(() => {

        IsValidToken(localStorage.getItem('Token')).then(async function (response) {
            await PreferredLimits();
        }

        ).catch(function (error) {
            navigate('/expired/');
        })

    }, []);

    async function PreferredLimits() {
        await GetPreferredLimits(localStorage.getItem('Token')).then(async function (response) {

            var i = 0;
            const filtered = response.Authentication.map(item => {

                const {
                    IconURL,
                    DepositName,
                    DepositID,
                    CreditCardTypeID,
                    PaymentType,
                    ImageName,
                    MinDeposit,
                    MaxDeposit,
                    ProcessorID,
                    RegCardID,
                    CardNumber,
                    ExpYear
                } = item;
                if ((item.PaymentType == "" ||
                    item.PaymentType == "Crypto" ||
                    item.PaymentType == "Western Union" ||
                    item.PaymentType == "Money Gram" ||
                    item.PaymentType == "generic")
                    ||
                    (item.PaymentType == "EWallet"
                        && (item.ImageName == "neteller" ||
                            item.ImageName.toLowerCase() == "paysafecard" ||
                            item.ImageName.toLowerCase() == "paypal" ||
                            item.ImageName.toLowerCase() == "braintree" ||
                            item.ImageName.toLowerCase() == "googlepay" ||
                            item.ImageName.toLowerCase() == "applepay" ||
                            item.ImageName.toLowerCase() == "premieret" ||
                            item.ImageName.toLowerCase() == "premierint" ||
                            item.ImageName.toLowerCase() == "premierplus" ||
                            item.ImageName.toLowerCase() == "pagomovil" ||
                            item.ImageName.toLowerCase() == "wipay" ||
                            item.ImageName.toLowerCase() == "quickervoucher" ||
                            item.ImageName.toLowerCase() == "quickerzelle" ||
                            item.ImageName.toLowerCase() == "apexglobal" ||
                            item.ImageName.toLowerCase() == "marketusa" ||
                            item.ImageName.toLowerCase() == "upay"
                        ))) {
                    return {
                        Key: i++,
                        IconURL,
                        DepositName,
                        DepositID,
                        CreditCardTypeID,
                        PaymentType,
                        ImageName,
                        MinDeposit,
                        MaxDeposit,
                        ProcessorID,
                        RegCardID,
                        CardNumber,
                        ExpYear
                    };
                }
            });
            await setArrayPriorityDeposits(filtered.filter(function (element) { return element !== undefined; }));

        }).catch(function (error) {


        })
    }

    function GetFormURL(Key) {
        if (arrayPriorityDeposits[Key].PaymentType.toLowerCase() == "")
            return "/deposits/creditcard/" + arrayPriorityDeposits[Key].DepositID
        else if (arrayPriorityDeposits[Key].PaymentType.toLowerCase() == "crypto")
            return "/deposits/crypto/" + arrayPriorityDeposits[Key].DepositID
        else if (arrayPriorityDeposits[Key].PaymentType.toLowerCase() == "ewallet")
            switch (arrayPriorityDeposits[Key].ImageName.toLowerCase()) {
                case "quickerzelle":
                    {
                        return "/deposits/zelle/";
                        break;
                    }
                default: {
                    return "/deposits/ewallet/" + arrayPriorityDeposits[Key].DepositID;
                    break;
                }
            }
        else if (arrayPriorityDeposits[Key].PaymentType.toLowerCase() == "money gram")
            return "/deposits/moneygram/"
        else
            return "";
    }
    
    return (
        <div className="mt-5">
            {arrayPriorityDeposits.length != 0 && < div className="title">
                <div className="container">
                    <h4 className="txt-primary text-title-color">Most popular methods</h4>
                </div>
            </div>}
            <div className="row">
                {arrayPriorityDeposits.map((item) => (
                    <div className="col-xxl-4 col-md-4 col-sm-4 col-xs-4">
                        <Link key={item.Key} to={GetFormURL(item.Key)} className="box-deposit box-green mb-2 mt-2">
                            <div className="row">
                                <div className="col-md-3 col-sm-12">
                                    <span className="icon-single">
                                        <img alt="" src={"/svg/" + item.ImageName + ".svg"} />
                                    </span>
                                </div>
                                <div className="col-md-8 col-sm-12 text-center limit-text">
                                    <span className="d-none d-md-block">Min:${item.MinDeposit == 0 ? 1 : item.MinDeposit} - Max:{item.MaxDeposit == 0 ? 'No Limit' : '$' + item.MaxDeposit}</span>

                                </div>
                                <div className="col-md-3 limit-text d-none d-md-block ">

                                </div>
                            </div>
                        </Link>
                    </div>
                ))}
            </div>
        </div>
        );
}