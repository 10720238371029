import { useEffect, useState } from 'react';
import { useNavigate, Link  } from "react-router-dom";
import clientInfo from '../login/ClientInfo';
import Alerts from '../helpers/Alerts';
import { IsValidToken } from "../../services/AuthenticationService";
import { GetDepositLimits } from "../../services/DepositService"
import Loading from '../helpers/Loader';

export const RegularMethods = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [arrayDeposits, setArrayDeposits] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {

        IsValidToken(localStorage.getItem('Token')).then(async function (response) {
            await DepositsLimits();
        }

        ).catch(function (error) {
                navigate('/expired/');
            })

     }, []);

    async function DepositsLimits() {
        await GetDepositLimits(localStorage.getItem('Token')).then(async function (response) {
            var i = 0;
            const filtered = response.Authentication.map(item => {

                const {
                    IconURL,
                    DepositName,
                    DepositID,
                    CreditCardTypeID,
                    PaymentType,
                    ImageName,
                    MinDeposit,
                    MaxDeposit,
                    ProcessorID
                } = item;
                if ((item.PaymentType == "Credit Card" ||
                    item.PaymentType == "Crypto" ||
                    item.PaymentType == "Western Union" ||
                    item.PaymentType == "Money Gram" ||
                    item.PaymentType == "generic")
                    ||
                    (item.PaymentType == "EWallet"
                        && (item.ImageName == "neteller" ||
                            item.ImageName.toLowerCase() == "paysafecard" ||
                            item.ImageName.toLowerCase() == "paypal" ||
                            item.ImageName.toLowerCase() == "braintree" ||
                            item.ImageName.toLowerCase() == "googlepay" ||
                            item.ImageName.toLowerCase() == "applepay" ||
                            item.ImageName.toLowerCase() == "premieret" ||
                            item.ImageName.toLowerCase() == "premierint" ||
                            item.ImageName.toLowerCase() == "premierplus" ||
                            item.ImageName.toLowerCase() == "pagomovil" ||
                            item.ImageName.toLowerCase() == "wipay" ||
                            item.ImageName.toLowerCase() == "quickervoucher" ||
                            item.ImageName.toLowerCase() == "quickerzelle" ||
                            item.ImageName.toLowerCase() == "apexglobal" ||
                            item.ImageName.toLowerCase() == "marketusa" ||
                            item.ImageName.toLowerCase() == "upay"
                        ))) {
                    return {
                        Key: i++,
                        IconURL,
                        DepositName,
                        DepositID,
                        CreditCardTypeID,
                        PaymentType,
                        ImageName,
                        MinDeposit,
                        MaxDeposit,
                        ProcessorID
                    };
                }
            });
            await setArrayDeposits(filtered.filter(function (element) { return element !== undefined;}));
            setIsLoading(false);
        }).catch(function (error) {
            setIsLoading(false);
            clientInfo.Error = false;
            Alerts.ShowAlert('Error', 'Error getting deposit limits info: ' + error.response.data.Authentication.ErrorDescription, 'error');
            navigate('/main/');

        })
    }

    function GetFormURL(Key) {
        if (arrayDeposits[Key].PaymentType.toLowerCase() == "credit card")
            return "/deposits/creditcard/" + arrayDeposits[Key].DepositID
        else if (arrayDeposits[Key].PaymentType.toLowerCase() == "crypto")
            return "/deposits/crypto/" + arrayDeposits[Key].DepositID
        else if (arrayDeposits[Key].PaymentType.toLowerCase() == "ewallet")
            switch (arrayDeposits[Key].ImageName.toLowerCase()) {
                case "quickerzelle":
                    {
                        return "/deposits/zelle/";
                        break;
                    }
                default: {
                    return "/deposits/ewallet/" + arrayDeposits[Key].DepositID;
                    break;
                }
            }
        else if (arrayDeposits[Key].PaymentType.toLowerCase() == "money gram")
            return "/deposits/moneygram/"
        else
            return "";
    }

    if (isLoading) {
        return <Loading />;
    }

    const handlePayoutSelect = (name) => {
        navigate(`/payouts/${name}`);
    }

    if (arrayDeposits.length == 0) {
        navigate('/main/');
    }

    localStorage.setItem('ArrayRegularMethods', JSON.stringify(arrayDeposits));

    return (
        <div className="mt-3">
            <div className="row">
                <div className="col-12">
                    <hr/>
                </div>
            </div>
            <div className="title">
                <div className="container">
                    <h4 className="txt-primary text-title-color">Regular methods</h4>
                </div>
            </div>
            <div className="row">
                {arrayDeposits.map((item) => (
                    <div className="col-xxl-4 col-md-4 col-sm-6 col-xs-6">
                        <Link key={item.Key} to={ GetFormURL(item.Key) } className="box-deposit  mb-4">
                            <div className="row">
                                <div className="col-md-3 col-sm-12">
                                    <span className="icon-single">
                                        <img alt="" src={"/svg/" + item.ImageName + ".svg"} />
                                    </span>
                                </div>
                                <div className="col-md-8 col-sm-12 text-center limit-text">
                                    <span>Min:${item.MinDeposit == 0 ? 1 : item.MinDeposit} - Max:{item.MaxDeposit == 0 ? 'No Limit' : '$' + item.MaxDeposit}</span>
                                </div>
                                <div className="col-md-3 limit-text d-none d-md-block ">

                                </div>
                            </div>
                        </Link>
                    </div>
                ))}
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <div onClick={() => handlePayoutSelect('')} className="box-deposit payoutmethod-color mb-4 text-center text-wrap height-68" >
                        <span className="othermethod-text text-center">WITHDRAWAL OPTIONS</span>
                    </div>
                </div>
                
            </div>
        </div>
    );
}