import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import Alerts from '../helpers/Alerts';
import { IsValidToken } from "../../services/AuthenticationService";
import { onlyNumbers } from '../helpers/common';
import Bonus from '../Bonus';

export const P2PMethods = () => {
    let MAXLIMIT = process.env.REACT_APP_MaxLimit;
    const [arrayRegularDeposits, setRegularDeposits] = useState(JSON.parse(localStorage.getItem('ArrayRegularMethods')).filter(function (element) { return element.DepositID == "MoneyGram"; })[0]);
    const navigate = useNavigate();

    useEffect(() => {
        IsValidToken(localStorage.getItem('Token')).then()
            .catch(function (error) {
                navigate('/expired/');
            })
    }, [])
    return (
        <div >
            <div className="row m-4">
                <span className="icon-single-left txt-primary">
                    <img style={{ height: "90px" }} src="/svg/moneygram.svg" /> Deposit using moneygram
                </span>
            </div>
            <div className="row">
                <div className="col-md-12 col-sm-12 col-xs-12 " >
                    <div className="row mb-4">
                        <div className="col-md-1 d-none d-md-block"></div>
                        <div className="col-md-10 col-sm-12">
                            <div class="accordion" id="accordionExample">
                                <div class="accordion-item">
                                    <h2 class="accordion-header ">
                                        <button class="accordion-button  text-center" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            <i className="fa fa-info-circle fa-2x " aria-hidden="true"></i> Click here if you have a&nbsp;&nbsp;<b>Control Number</b>
                                        </button>
                                    </h2>
                                    <div id="collapseOne" class="accordion-collapse collapse hide" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-md-3 col-sm-12 col-xs-12">
                                                        <label className="mt-2" >My Requested Receivers:</label>
                                                    </div>
                                                        
                                                        <div class="col-md-9 col-sm-12 col-xs-12">
                                                            <table class="transaction-result table">
                                                                <thead class="bg-primary">
                                                                    <tr>
                                                                        <th><center class="ng-binding">Tnx ID</center></th>
                                                                        <th class="ng-binding">Receiver</th>
                                                                        <th class="ng-binding">Amount</th>
                                                                        <th><center class="ng-binding">Address</center></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr ng-repeat="item in TransactionHistoryList" class="ng-scope" >
                                                                        <td class="ng-binding">
                                                                            <input type="radio" name="radgroup" ng-model="tnxlist.selectedOption" ng-value="item.TransactionID" class="ng-pristine ng-untouched ng-valid ng-not-empty" value="510903" />510903
                                                                        </td>
                                                                        <td class="ng-binding">YING ELIASON</td>
                                                                        <td class="ng-binding">10</td>
                                                                        <td><center class="ng-binding">Managua,Nicaragua</center></td>
                                                                    </tr><tr ng-repeat="item in TransactionHistoryList" class="ng-scope">
                                                                        <td class="ng-binding"><input type="radio" name="radgroup" ng-model="tnxlist.selectedOption" ng-value="item.TransactionID" class="ng-pristine ng-untouched ng-valid ng-not-empty" value="510263" />510263</td>
                                                                        <td class="ng-binding">YOLANDA PITMAN</td>
                                                                        <td class="ng-binding">57</td>
                                                                        <td><center class="ng-binding">Managua,Nicaragua</center></td>
                                                                    </tr><tr ng-repeat="item in TransactionHistoryList" class="ng-scope">
                                                                        <td class="ng-binding"><input type="radio" name="radgroup" ng-model="tnxlist.selectedOption" ng-value="item.TransactionID" class="ng-pristine ng-untouched ng-valid ng-not-empty" value="510251" />510251</td>
                                                                        <td class="ng-binding">ZACHARY SHEDD</td>
                                                                        <td class="ng-binding">72</td>
                                                                        <td><center class="ng-binding">Managua,Nicaragua</center></td>
                                                                    </tr><tr ng-repeat="item in TransactionHistoryList" class="ng-scope">
                                                                        <td class="ng-binding"><input type="radio" name="radgroup" ng-model="tnxlist.selectedOption" ng-value="item.TransactionID" class="ng-pristine ng-untouched ng-valid ng-not-empty" value="510250" />510250</td>
                                                                        <td class="ng-binding">WILFORD BERGMANN</td>
                                                                        <td class="ng-binding">89</td>
                                                                        <td><center class="ng-binding">Managua,Nicaragua</center></td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                </div>
                                                <div className="row mb-4">
                                                    <div className="col-md-3 col-sm-12">
                                                        <label for="controlnumber" >Control Number:</label><br />
                                                    </div>
                                                    <div className="col-md-4 col-sm-12">
                                                        <input type="text" className="form-control border-teal" aria-label="Control Number" placeholder="Control Number" name="controlnumber" maxlength="8" ng-minlength="8" />
                                                        <span className="text-danger">Control number required.</span>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <button type="button" className="btn btn-deposit btn-deposit-order mb-4" ng-click="submitOtherDepositStep2()">Complete</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-1 d-none d-md-block"></div>
                    </div>
                    <div className="row">
                        <div className="col-md-1 d-none d-md-block"></div>
                        <div className="col-md-5 col-sm-12" style={{ verticalAlign: "middle" }}>
                            <div className="d-flex flex-row">
                                <div className="d-flex flex-column ml-2 text-left  w-100">
                                    <label className="d-md-none">Amount</label>
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-currency" id="lblCurrency">USD</span>
                                        </div>
                                        <input type="number" class="form-control" id="amount" aria-describedby="lblCurrency" placeholder="Amount" onKeyPress={(event) => onlyNumbers(event)} className="form-control color-gray border-teal" aria-label="Amount (to the nearest dollar)" placeholder="Amount" name="ewamount" max={arrayRegularDeposits?.MaxDeposit == 0 ? MAXLIMIT : arrayRegularDeposits?.MaxDeposit} min={arrayRegularDeposits?.MinDeposit == 0 ? 1 : arrayRegularDeposits?.MinDeposit} required=""  />
                                    </div>

                                    <div className="d-flex flex-row ml-2 limit-text-center ">
                                        Deposit Limits: Minimum {arrayRegularDeposits?.MinDeposit == 0 ? 1 : arrayRegularDeposits?.MinDeposit} USD Maximum {arrayRegularDeposits?.MaxDeposit == 0 ? 'No Limit' : arrayRegularDeposits?.MaxDeposit + ' USD'}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5 col-sm-12">
                            <div className="row">
                                <label for="p2palias" className="col-md-2 col-sm-12 align-middle">Alias:</label>
                                <div className="col-md-10 col-sm-12">
                                    <select className="form-control form-select border-teal " name="p2palias" required="required">
                                        <option value="">- Please Choose -</option>
                                        <option value="John D. Smith NA" >John D. Smith NA</option>
                                        <option value="John Doe Smith NA">John Doe Smith NA</option>
                                        <option value="John Doe S. NA">John Doe S. NA</option>
                                        <option value="John Smith Doe NA">John Smith Doe NA</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-1 d-none d-md-block"></div>
                    </div>
                    <div className="row mb-3" >
                        <div className="col-md-1 d-none d-md-block"></div>
                       
                        <div className="col-md-1 d-none d-md-block"></div>
                    </div>
                    <div className="row">
                        <div className="col-md-1 d-none d-md-block"></div>
                        <div className="col-md-10 col-sm-12">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="row  mb-3">
                                        <label for="country" className="col-md-4 col-sm-12">Country:</label>
                                        <div className="col-md-8 col-sm-12 ">
                                            <input type="text" className="form-control color-gray border-teal" name="country" aria-label="country" placeholder="Country" disabled="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="row  mb-3">
                                        <label for="state" className="col-md-4 col-sm-12">State / Prov:</label>
                                        <div className="col-md-8 col-sm-12">
                                            <input type="text" className="form-control color-gray border-teal" name="state" aria-label="state" placeholder="State / Prov" disabled="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-1 d-none d-md-block"></div>
                    </div>
                    <div className="row">
                        <div className="col-md-1 d-none d-md-block"></div>
                        <div className="col-md-10 col-sm-12">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="row  mb-3">
                                        <label for="city" className="col-md-4 col-sm-12">City:</label>
                                        <div className="col-md-8 col-sm-12">
                                            <input type="text" className="form-control color-gray border-teal" name="city" aria-label="city" placeholder="City" disabled="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="row  mb-3">
                                        <label for="phone" className="col-md-4 col-sm-12">Phone:</label>
                                        <div className="col-md-8 col-sm-12">
                                            <input type="text" className="form-control color-gray border-teal" name="phone" aria-label="phone" placeholder="Phone" disabled="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-1 d-none d-md-block"></div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-md-1 "></div>
                        <div className="col-md-10 col-sm-12 col-xs-12 text-center">
                            <span className="">
                                Please note your person to person instructions will be valid for next 72 hours, after this time you will have to request new ones.
                            </span>
                        </div>
                        <div className="col-md-1 d-none d-md-block"></div>
                    </div>
                </div>
                <Bonus />
                <div className="col-md-12 mb-3-small mt-4">
                    <button type="button" className="btn btn-secondary btn-gray btn-back-order mb-4 d-none d-md-block" onClick={() => navigate('/deposits/')}>BACK</button>
                    <button className="btn btn-deposit btn-deposit-order mb-4 submit">INSTRUCTIONS</button>
                </div>
            </div>



        </div>
    );
}