import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { LoginPage } from './pages/Login';
import { ExpressPage } from "./pages/ExpressPage";
import { DepositsPage } from './pages/Deposits';
import { DepositsCreditCardPage } from './pages/DepositsCreditCard';
import { DepositsZellePage } from './pages/DepositZelle';
import { DepositsP2PPage } from './pages/DepositsP2P';
import { DepositCryptoPage } from './pages/DepositCrypto';
import { DepositEwalletPage } from './pages/DepositEwallet';
import { PayoutsPage } from './pages/Payouts';
import { PayoutCryptoPage } from './pages/PayoutCrypto';
import { PayoutGenericPage } from './pages/PayoutGeneric';
import { ExpiredPage } from './pages/expired';
import { PayoutHistoryPage } from './pages/PayoutHistory'

function App() {
    //const client = new QueryClient();

    return (
        <BrowserRouter basename="/">
            <Routes>
                <Route path="/" element={<LoginPage />} />
                <Route path="/main/" element={<ExpressPage />} />
                <Route path="/deposits/:id" element={<DepositsPage />} />
                <Route path="/deposits/" element={<DepositsPage />} />
                <Route path="/deposits/creditcard/:id" element={<DepositsCreditCardPage />} />
                <Route path="/deposits/zelle" element={<DepositsZellePage />} />
                <Route path="/deposits/moneygram" element={<DepositsP2PPage />} />
                <Route path="/deposits/crypto/:id" element={<DepositCryptoPage />} />
                <Route path="/deposits/ewallet/:id" element={<DepositEwalletPage />} />                
                <Route path="/payouts/" element={<PayoutsPage />} />
                <Route path="/payoutHistory/" element={<PayoutHistoryPage />} />
                <Route path="/payouts/crypto/:id" element={<PayoutCryptoPage />} />
                <Route path="/payouts/generic/:id" element={<PayoutGenericPage />} />
                <Route path="/expired/" element={<ExpiredPage/> } />
            </Routes>
        </BrowserRouter>

    );
}

export default App;
