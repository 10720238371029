import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import clientInfo from '../login/ClientInfo';
import Alerts from '../helpers/Alerts';
import { IsValidToken } from "../../services/AuthenticationService";
import { GetQuickerDirectZelle, QuickerInitiateTransaction } from "../../services/DepositService";
import Loading from '../helpers/Loader';
import BeatLoader from "react-spinners/BeatLoader"
import { onlyNumbers, BonusString } from '../helpers/common';
import Bonus from '../Bonus';

export const ZelleMethods = () => {
    let MAXLIMIT = process.env.REACT_APP_MaxLimit;
    const [isLoading, setIsLoading] = useState(true);
    const [isProcessing, setIsProcessing] = useState(false);
    const navigate = useNavigate();
    const [arrayZelleDeposits, setZelleDeposits] = useState(JSON.parse(localStorage.getItem('ArrayRegularMethods')).filter(function (element) { return element.DepositID == "QuickerZelle"; })[0]);
    const [arrayDirectZelle, setDirectZelle] = useState([]);
    const [arrayInitiateTransaction, setInitiateTransaction] = useState([]);
    const [showInstructions, setshowInstructions] = useState(true);
    const [showResponse, setshowResponse] = useState(false);
    const [disableControl, setDisableControl] = useState(false);

    useEffect(() => {

        IsValidToken(localStorage.getItem('Token')).then(async function (response) {
            await QuickerDirectZelle();
        }

        ).catch(function (error) {
            navigate('/expired/');
        })

        if (arrayZelleDeposits == undefined) {
            navigate('/deposits/');
        }
        

    }, []);

    async function QuickerDirectZelle() {
            await GetQuickerDirectZelle(localStorage.getItem('Token'), arrayZelleDeposits.ProcessorID).then(async function (response) {
                
            const filtered = response.Authentication.BankAccounts.map(item => {
                const {
                    AccountName,
                    AccountNumber,
                    AccountType,
                    BankAccountID,
                    BankName,
                    RoutingNumber,
                    RoutingPaperCheck,
                    RoutingWire
                } = item;{
                    return {
                        AccountName,
                        AccountNumber,
                        AccountType,
                        BankAccountID,
                        BankName,
                        RoutingNumber,
                        RoutingPaperCheck,
                        RoutingWire
                    };
                }
            });
            await setDirectZelle(filtered.filter(function (element) { return element.BankName == "Zelle"; })[0]);
            setIsLoading(false);
        })
            .catch(function (error) {
                setIsLoading(false);
                Alerts.ShowAlert('Error', 'Error bank accounts', 'error');
            })

    }

    async function InitiateTransaction(Amount) {
        await QuickerInitiateTransaction(localStorage.getItem('Token'), arrayZelleDeposits.ProcessorID, Amount, arrayDirectZelle.BankAccountID, BonusString(JSON.parse(localStorage.getItem('ArrayBonus')))).then(async function (response) {
            
            await setInitiateTransaction(response.Authentication);
            
                 })
            .catch(function (error) {
                setIsLoading(false);
                Alerts.ShowAlert('Error', 'Error initiate transaction', 'error');
            })

    }
    const handleSubmit2 = async (event) => {
        event.preventDefault();
        console.log(BonusString(JSON.parse(localStorage.getItem('ArrayBonus'))));
    }
    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!isProcessing) {
            var data = new FormData(event.target);
            let { ewamount } = Object.fromEntries(data.entries());
            if (ewamount != '') {
                setDisableControl(true);
                setIsProcessing(true);
                await InitiateTransaction(ewamount);
                setIsProcessing(false);
                setshowInstructions(false);
                setshowResponse(true);
            }
            else {
                Alerts.ShowAlert('Warning', 'Amount required', 'warning');
            }
        }
    }

    if (isLoading) {
        return <Loading />;
    }

    return (
        <div>
            <div className="row">
                <div className="col-md-12 col-sm-12 icon-single-left logo-highlight">
                    <span className="xt-primary">
                        <img style={{ width: "140px" }} src="/svg/zelle.svg" />  Deposit using Zelle
                    </span>
                </div>
            </div>
            
            <form onSubmit={handleSubmit}>
                <div className={showInstructions ? "show-element" : null}>
                    {showInstructions && <div className="row" >
                        <div class="col-md-12" >
                            <div className="col-md-12 warning badge-zelle max-limit" role="alert">
                                <p className="center-block">
                                    <b>INSTRUCTIONS</b>
                                    <br />
                                    <br />1. Enter the deposit amount.
                                    <br />2. Once you click <b>"NEXT"</b> you will receive a confirmation code by email and SMS.<br />
                                    (You will need this code when making the deposit)
                                    <br />3. Make the deposit for the amount entered via ZELLE by using your Online Banking App or ZELLE App.<br />
                                    (ZELLE App is available on Apple App Store or Google Play Store)<br />
                                </p>
                            </div>
                        </div>
                        <div class="col-md-12" >
                            <br />
                        </div>
                        <div className="offset-md-3 col-md-6 col-sm-12 col-xs-12 mb-3" >
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-currency-cards d-none d-md-block" id="lblCurrency">
                                        <select className="form-control selectcurrency" disabled="" name="ccCurrency" required="" value={clientInfo.CurrencyCode}>
                                            <option value="" className="">Currency</option>
                                            <option value="USD">USD</option>
                                        </select>
                                    </span>
                                </div>
                                <input type="number" disabled={disableControl} onKeyPress={(event) => onlyNumbers(event)}  className="form-control color-gray border-teal" aria-label="Amount (to the nearest dollar)" placeholder="Amount" name="ewamount" max={arrayZelleDeposits?.MaxDeposit == 0 ? MAXLIMIT : arrayZelleDeposits?.MaxDeposit} min={arrayZelleDeposits?.MinDeposit == 0 ? 1 : arrayZelleDeposits?.MinDeposit} aria-describedby="lblCurrency" required="" />
                            </div>
                            <div className="d-flex flex-row ml-2 limit-text-center ">
                                Deposit Limits: Minimum {arrayZelleDeposits?.MinDeposit == 0 ? 1 : arrayZelleDeposits?.MinDeposit} USD Maximum {arrayZelleDeposits?.MaxDeposit == 0 ? 'No Limit' : arrayZelleDeposits?.MaxDeposit + ' USD'}
                            </div>
                        </div>
                        <Bonus />
                        <div className="col-md-12 mb-3-small mt-4">
                            <button type="button" disabled={disableControl} className="btn btn-secondary btn-gray btn-back-order mb-4 d-none d-md-block" onClick={() => navigate('/deposits/')}>BACK</button>
                            <button type="submit" disabled={disableControl} className="btn btn-deposit btn-lightgreen btn-deposit-order mb-4 submit">NEXT</button>
                        </div>
                    </div>}
                </div>
            </form>

            <div className={showResponse ? "show-element" : null}>
                {showResponse && <div className="row" >
                <div className="col-md-12 col-sm-12 col-xs-12 " >
                        <div className="row">
                        <div className="col-md-5 col-sm-3 col-xs-12 text-end">
                                ZELLE:
                            </div>
                            <div className="col-md-7 col-sm-9 col-xs-12">
                                <b >{arrayDirectZelle.AccountNumber}</b>
                            </div>
                            <br/><br/>
                        <div className="col-md-5 col-sm-3 col-xs-12 text-end">
                                Business Name:
                            </div>
                            <div className="col-md-7 col-sm-9 col-xs-12 ">
                                <b >{arrayDirectZelle.AccountName}</b>
                            </div>
                            <br /><br />
                        <div className="col-md-5 col-sm-3 col-xs-12 text-end">
                                Code:
                            </div>
                            <div className="col-md-7 col-sm-9 col-xs-12">
                            <b >{arrayInitiateTransaction.Descriptor}</b>
                            </div>
                            <br /><br />
                        <div className="col-md-5 col-sm-3 col-xs-12 text-end">
                                Message or Memo:
                            </div>
                            <div className="col-md-7 col-sm-9 col-xs-12">
                            <b>Please include your CONFIRMATION CODE received by email or SMS.</b>
                            <br /><br />
                            </div>
                            <div className="col-md-12 warning badge-zelle max-limit" role="alert">
                                    <p className="center-block">
                                        <b >IMPORTANT</b><br/><br/>
                                            Please make sure to include the email or SMS <b>confirmation code</b> in the "MESSAGE" or "MEMO"<br/>
                                                field when making the deposit in your Bank App or ZELLE App or else your deposit will not be credited.
                                    </p>
                            </div>
                        </div>
                </div>
                <div className="col-md-12 mb-3-small mt-4">
                        <button type="button" className="btn btn-secondary btn-gray btn-back-order mb-4 d-none d-md-block" onClick={() => navigate('/deposits/')}>BACK</button>
                        <button className="btn btn-deposit btn-lightgreen btn-deposit-order mb-4 submit" onClick={() => navigate('/deposits/') }>FINISH</button>
                </div>
                </div>}
            </div>

            <div >
                {isProcessing && <div className="loader-fullcontainer">
                    <div className="loader-container">
                        <BeatLoader color="#36d7b7" size={60} />
                    </div>
                </div>}
            </div>

        </div>
    );
}