import { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import Alerts from '../helpers/Alerts';
import { IsValidToken, GetCustomerInfo, GetCountries, GetStatesbyCountry } from "../../services/AuthenticationService";
import { GetGenericPayoutFormProperties, GenericPayout } from "../../services/PayoutService";
import Loading from '../helpers/Loader';
import { onlyNumbers, BonusString } from '../helpers/common';
import clientInfo from '../login/ClientInfo';
import Bonus from '../Bonus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isMobile } from 'react-device-detect';
import { faUsd, faMoneyBill, faAddressBook, faPhone, faUser, faThLarge, faEnvelopeOpen, faGlobe, faBuilding, faLocationArrow, faAddressCard, faUniversity  } from '@fortawesome/free-solid-svg-icons'

export const PayoutGenericMethods = () => {
    let MAXLIMIT = process.env.REACT_APP_MaxLimit;
    const navigate = useNavigate();
    const { id } = useParams();
    const [arrayGenericForm, setArrayGenericForm] = useState([]);
    const [arrayCustomerInfo, setArrayCustomerInfo] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [arrayPayouts, setarrayPayouts] = useState(JSON.parse(localStorage.getItem('arrayPayoutsMethods')).filter(function (element) { return element.ProcessorID == id; })[0]);
    const [isProcessing, setIsProcessing] = useState(false);
    const [arrayCountries, setArrayCountries] = useState([]);
    const [arrayStates, setArrayStates] = useState([]);
    const [stateCode, setStateCode] = useState('');
    const [countryCode, setCountryCode] = useState('');
    const [currencyCode, setCurrencyCode] = useState('');
    

    useEffect(() => {
        IsValidToken(localStorage.getItem('Token')).then(async function (response) {
            await GetCountries(localStorage.getItem('Token')).then(async function (response) {
                var i = 0;
                const filtered = response.Authentication.map(item => {
                    const {
                        Code,
                        Name } = item;

                    return { Key: i++, Code, Name }
                });
                setArrayCountries(filtered);
                setCountryCode('US');
                setCurrencyCode(clientInfo.CurrencyCode);
                setStateCode(clientInfo.StateCode);
                handleStatebyCountry('US', true);
            });
        })
            .catch(function (error) {
                navigate('/expired/');
            })

        GetGenericFormProperties();
        CustomerInfo();

    }, [])

    async function CustomerInfo() {
        await GetCustomerInfo(localStorage.getItem('Token')).then(function (response) {
            if (response.Authentication.ErrorDescription != "Invalid input data") {
                setArrayCustomerInfo(response.Authentication);

            }
            else {
                clientInfo.Error = false;
                Alerts.ShowAlert('Error', 'Error getting customer info: ' + response.Authentication.ErrorDescription, 'error');

            }
        })
            .catch(function (error) {
                clientInfo.Error = false;
                Alerts.ShowAlert('Error', 'Error getting customer info: ' + error.response.data.Authentication.ErrorDescription, 'error');
            })
    }
    
    async function GetGenericFormProperties() {
        var PayoutData = {
            "Token": localStorage.getItem('Token'),
            "ProcessorID": arrayPayouts.ProcessorID
        };
        await GetGenericPayoutFormProperties(PayoutData).then(async function (response) {

            await setArrayGenericForm(response.Authentication);
            if (response.Authentication.ErrorDescription != null) {
                Alerts.ShowAlert('Error', response.Authentication.ErrorDescription, 'error');
                navigate('/payouts/');
            }
            await setIsLoading(false);

        })
            .catch(function (error) {
                setIsLoading(false);
                Alerts.ShowAlert('Error', error.response.data.Authentication.ErrorDescription, 'error');
            })


    }

    async function doGenericPayout(data) {

        await GenericPayout(data).then(async function (response) {

            if (isMobile)
                Alerts.ShowAlertFullScreen('Payout Status', response.Authentication.HtmlResponse, 'info');
            else
                Alerts.ShowAlert('Payout Status', response.Authentication.HtmlResponse, 'info');

            navigate('/payouts/');
        })
            .catch(function (error) {
                setIsLoading(false);
                Alerts.ShowAlert('Error', error.response.data.Authentication.ErrorDescription, 'error');
            })


    }

    const handleStatebyCountry = async (selectedCountryCode, setState) => {
        await GetStatesbyCountry(selectedCountryCode).then(async function (response) {
            
            var i = 0;
            const filtered = response.Authentication.map(item => {
                const {
                    Code,
                    Name } = item;

                return { Key: i++, Code, Name }
            });
            setArrayStates(filtered);
            if (setState) {
                if (filtered.length > 1)
                    setStateCode(filtered[1].Code);
                else
                    setStateCode(filtered[0].Code);
            }
        })
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!isProcessing) {
            var data = new FormData(event.target);
            let { Amount, Identifier, GeneralID, FirstName, LastName, Phone, City, Address, Notes, Email, Address2, ZipCode } = Object.fromEntries(data.entries());            
            if (Amount != '') {
                setIsLoading(true);
                setIsProcessing(true);
                var formData = {
                    "Token": localStorage.getItem('Token'),
                    "ProcessorID": arrayPayouts.ProcessorID,
                    "CurrencyCode": currencyCode,
                    "Identifier": (Identifier != undefined ? Identifier : ''),
                    "GeneralID": (GeneralID != undefined ? GeneralID : ''),
                    "FirstName": (FirstName != undefined ? FirstName : arrayCustomerInfo.FirstName),
                    "LastName": (LastName != undefined ? LastName : arrayCustomerInfo.LastName),
                    "Phone": (Phone != undefined ? Phone : arrayCustomerInfo.Phone),
                    "Email": (Email != undefined ? Email : arrayCustomerInfo.Email),
                    "Address": (Address != undefined ? Address : arrayCustomerInfo.Address),
                    "Address2": (Address2 != undefined ? Address2 : arrayCustomerInfo.Address2),
                    "Country": countryCode,
                    "State": stateCode,
                    "City": (City != undefined ? City : arrayCustomerInfo.City),
                    "ZipCode": (ZipCode != undefined ? ZipCode : arrayCustomerInfo.ZipCode),
                    "Amount": Amount,
                    "IPv4Address": clientInfo.IPAddress,
                    "Notes": (Notes != undefined ? Notes :''),
                    "BonusList": '', //BonusString(JSON.parse(localStorage.getItem('ArrayBonus'))),
                };
                
                await doGenericPayout(formData);
                setIsProcessing(false);
                setIsLoading(false);

            }
            else {
                Alerts.ShowAlert('Warning', 'Amount required', 'warning');
            }

        }
    }

    if (isLoading) {
        return <Loading />;
    }

    const CurrencyOptions = [       
        { key: "1", value: "USD", name: "USD" },
        { key: "2", value: "EUR", name: "EUR" },
        { key: "3", value: "GBP", name: "GBP" },
        { key: "4", value: "AUD", name: "AUD" }
    ];
 
    return (
        <div className="main-content payout container">
            <div>
                {/*<span ng-show="!genericPayoutMethod">NoMethod</span>*/}
                <div className="tab-content clear">
                    <form onSubmit={handleSubmit} ng-submit="submitGeneric()">
                        <div className="row m-4">
                            <span className="icon-single-left txt-primary">
                                <img style={{ width: "100px", height: "100px" }} src={"data:image/svg+xml;base64," + arrayPayouts?.ImageName} /> <span className="payout-title"> Withdraw using {arrayPayouts?.PayoutID} </span>
                            </span>
                        </div>
                        {/*Fields */}
                        <div className="row">
                            <div className="col-xs-12">
                                <br />
                            </div>
                        </div>
                        <div className="row  m3">
                            <div className="col-md-4 col-sm-4 col-xs-12 mb-3">
                                <div className="input-group" >
                                    <div className= "input-group-prepend">
                                        <FontAwesomeIcon className="input-group-fa" icon={faUsd} />
                                    </div>
                                    <select className="form-select"  name="Currency" onChange={(event) => { setCurrencyCode(event.target.value) }} required
                                        tooltip-placement="bottom" tooltip-trigger="focus" tooltip-class="customClass" uib-tooltip="Select the Currency"                                        >
                                        {CurrencyOptions.map((item) => <option key={item.Key} selected={item.Code == clientInfo.CurrencyCode} value={item.value}>{item.name}</option>)}
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-4 col-xs-12  mb-3">
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <FontAwesomeIcon className="input-group-fa" icon={faMoneyBill} />
                                    </div>
                                    <input type="number" className="form-control" aria-label="Amount (to the nearest dollar)" onKeyPress={(event) => onlyNumbers(event)} placeholder="Amount"
                                        name="Amount" required={arrayGenericForm.Identifier.Required}
                                        max={arrayPayouts?.MaxPayout == 0 ? MAXLIMIT : arrayPayouts?.MaxPayout} min={arrayPayouts?.MinPayout == 0 ? 1 : arrayPayouts?.MinPayout} />
                                    
                                </div>
                                <p className="center-block d-flex flex-row ml-2 limit-text-center">PayoutLimits  Minimum {arrayPayouts?.MinPayout == 0 ? 1 : arrayPayouts?.MinPayout} USD Maximum {arrayPayouts?.MaxPayout == 0 ? 'No Limit' : arrayPayouts?.MaxPayout + ' USD'}</p>

                            </div>
                            {arrayGenericForm.Identifier.Visible && <div className="col-md-4 col-sm-4 col-xs-12  mb-3" >
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <FontAwesomeIcon className="input-group-fa" icon={faAddressBook} />
                                    </div>
                                    <input type="tel" className="form-control" name="Identifier" disabled={!arrayGenericForm.Identifier.Editable}
                                        aria-label="Account" placeholder={arrayGenericForm.lblIdentifier == '' ? "Account" : arrayGenericForm.lblIdentifier} required={arrayGenericForm.Identifier.Required} />
                                </div>
                            </div>}
                            {arrayGenericForm.GeneralID.Visible && <div className="col-md-4 col-sm-4 col-xs-12  mb-3">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <FontAwesomeIcon className="input-group-fa" icon={faThLarge} />
                                    </div>
                                    <input type="tel" className="form-control" name="GeneralID" disabled={!arrayGenericForm.GeneralID.Editable}
                                        aria-label="TransactionID" placeholder={arrayGenericForm.lblGeneralID == '' ? "TransactionID" : arrayGenericForm.lblGeneralID} required={arrayGenericForm.GeneralID.Required} />
                                </div>
                            </div>}
                            {arrayGenericForm.FirstName.Visible && <div className="col-md-4 col-sm-4 col-xs-12  mb-3" >
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <FontAwesomeIcon className="input-group-fa" icon={faUser} />
                                    </div>
                                    <input type="text" className="form-control" name="FirstName" disabled={!arrayGenericForm.FirstName.Editable} value={clientInfo.Name}
                                        aria-label="FirstName" placeholder={arrayGenericForm.lblFirstName == '' ? "First Name" : arrayGenericForm.lblFirstName} required={arrayGenericForm.FirstName.Required} />
                                </div>
                            </div>}

                            {arrayGenericForm.LastName.Visible && <div className="col-md-4 col-sm-4 col-xs-12  mb-3">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <FontAwesomeIcon className="input-group-fa" icon={faUser} />
                                    </div>
                                    <input type="text" className="form-control" name="LastName" disabled={!arrayGenericForm.LastName.Editable} value={clientInfo.LastName}
                                        aria-label="LastName" placeholder={arrayGenericForm.lblLastName == '' ? "Last Name" : arrayGenericForm.lblLastName} required={arrayGenericForm.LastName.Required} />
                                </div>
                            </div>}
                        </div>
                        <div className="row">
                            {arrayGenericForm.Phone.Visible && <div className="col-md-4 col-sm-4 col-xs-12  mb-3" >
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <FontAwesomeIcon className="input-group-fa" icon={faPhone} />
                                    </div>
                                    <input type="text" className="form-control" name="Phone" disabled={!arrayGenericForm.Phone.Editable} value={arrayCustomerInfo.Phone}
                                        aria-label="Phone" placeholder={arrayGenericForm.lblPhone == '' ? "Phone" : arrayGenericForm.lblPhone} required={arrayGenericForm.Phone.Required} />
                                </div>
                            </div>}
                            {arrayGenericForm.Email.Visible && <div className="col-md-4 col-sm-4 col-xs-12  mb-3" ng-show="arrayGenericForm.Email.Visible">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <FontAwesomeIcon className="input-group-fa" icon={faEnvelopeOpen} />
                                    </div>
                                    <input type="text" className="form-control" name="Email" disabled={!arrayGenericForm.Email.Editable} value={arrayCustomerInfo.Email}
                                        aria-label="Email" placeholder={arrayGenericForm.lblEmail == '' ? "Email" : arrayGenericForm.lblEmail} required={arrayGenericForm.Email.Required} />
                                </div>
                            </div>}

                            {arrayGenericForm.Country.Visible && <div className="col-md-4 col-sm-4 col-xs-12  mb-3" >
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <FontAwesomeIcon className="input-group-fa" icon={faGlobe} />
                                    </div>
                                    <select className="form-select" name="Country" disabled={!arrayGenericForm.Country.Editable} required={arrayGenericForm.Country.Required}
                                            onChange={(event) => { setCountryCode(event.target.value); handleStatebyCountry(event.target.value, true) }}
                                        tooltip-placement="bottom" tooltip-trigger="focus" tooltip-class="customClass" uib-tooltip="Select the Country" >
                                        {arrayCountries.map((item) => <option key={item.Key} selected={item.Code == arrayCustomerInfo.CountryCode} value={item.Code}>{item.Name}</option>)}
                                    </select>

                                </div>
                            </div>}
                            {arrayGenericForm.State.Visible && <div className="col-md-4 col-sm-4 col-xs-12  mb-3" >
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <FontAwesomeIcon className="input-group-fa" icon={faGlobe} />
                                    </div>
                                    <select className="form-select" value={stateCode} name="StateCode" disabled={!arrayGenericForm.State.Editable} onChange={(event) => setStateCode(event.target.value)}
                                        tooltip-placement="bottom" tooltip-trigger="focus" tooltip-className="customClass" uib-tooltip="Select the State" required={arrayGenericForm.State.Required}>
                                        {arrayStates.map((item) => <option key={item.Key} selected={item.Code == arrayCustomerInfo.StateCode}  value={item.Code}>{item.Name}</option>)}
                                    </select>

                                </div>
                            </div>}
                            {arrayGenericForm.City.Visible && <div className="col-md-4 col-sm-4 col-xs-12 mb-3" >
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <FontAwesomeIcon className="input-group-fa" icon={faBuilding} />
                                    </div>
                                    <input type="text" className="form-control" name="City" disabled={!arrayGenericForm.City.Editable} value={arrayCustomerInfo.City}
                                        aria-label="City" placeholder={arrayGenericForm.lblCity == '' ? "City" : arrayGenericForm.lblCity} required={arrayGenericForm.City.Required} />
                                </div>
                            </div>}
                            {arrayGenericForm.ZipCode.Visible && <div className="col-md-4 col-sm-4 col-xs-12 mb-3">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <FontAwesomeIcon className="input-group-fa" icon={faLocationArrow} />
                                    </div>
                                    <input type="text" className="form-control" name="ZipCode" disabled={!arrayGenericForm.ZipCode.Editable} value={arrayCustomerInfo.ZipCode}
                                        aria-label="ZipCode" placeholder={arrayGenericForm.lblZipCode == '' ? "Zip Code" : arrayGenericForm.lblZipCode} required={arrayGenericForm.ZipCode.Required} />
                                </div>
                            </div>}
                        </div>
                        <div className="row">
                            {arrayGenericForm.Address.Visible && <div className="col-md-6 col-sm-6 col-xs-12 mb-3" >
                                <div className="input-group " >
                                    <div className="input-group-prepend">
                                        <FontAwesomeIcon className="input-group-fa" icon={faAddressCard} />
                                    </div>
                                    <input type="text" className="form-control" name="Address" disabled={!arrayGenericForm.Address.Editable} value={arrayCustomerInfo.Address}
                                        aria-label="Address" placeholder={arrayGenericForm.lblAddress == '' ? "Address" : arrayGenericForm.lblAddress} required={arrayGenericForm.Address.Required} />
                                </div>
                            </div>}
                            {arrayGenericForm.Address2.Visible && <div className="col-md-6 col-sm-6 col-xs-12 mb-3" >
                                <div className="input-group" >
                                    <div className="input-group-prepend">
                                        <FontAwesomeIcon className="input-group-fa" icon={faAddressCard} />
                                    </div>
                                    <input type="text" className="form-control" name="Address2" disabled={!arrayGenericForm.Address2.Editable} value={arrayCustomerInfo.Address2}
                                        aria-label="Address2" placeholder={arrayGenericForm.lblAddress2 == '' ? "Address 2" : arrayGenericForm.lblAddress2} required={arrayGenericForm.Address2.Required} />
                                </div>
                            </div>}
                        </div>
                        <div className="row">
                            {arrayGenericForm.Notes.Visible && <div className="col-md-12 col-sm-12 col-xs-12 mb-3" >
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <FontAwesomeIcon className="input-group-fa" icon={faUniversity} />
                                    </div>
                                    <input type="text" className="form-control" name="Notes" disabled={!arrayGenericForm.Notes.Editable}
                                        aria-label="Notes" placeholder={arrayGenericForm.lblNotes == '' ? "Notes" : arrayGenericForm.lblAddress2} required={arrayGenericForm.Notes.Required} />
                                </div>
                            </div>}
                        </div>
                        {/*bonus*/}

                        <div className="row">
                            <div className="col-md-12 mb-3-small mt-4">
                                <button type="button" className="btn btn-secondary btn-gray btn-back-order mb-4 d-none d-md-block" onClick={() => navigate('/payouts/')}>BACK</button>
                                <button type="submit" className="btn btn-deposit btn-deposit-order mb-4 submit">SUBMIT</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}