import  Axios  from "axios"

var BASEURL = process.env.REACT_APP_BASEURL

export const GenerateToken = (username, password, instanceId, SkinId, BookId) => {

    var loginData = {
        "userLogin": username,
        "userPassword": password,
        "InstanceID": instanceId,
        "SkinID": SkinId,
        "BookID": BookId
    };

    return Axios.post(BASEURL + "api/Authentication/GenerateToken", loginData).then((res) => res.data);
}

export const GetBalance = (Token) => {

    var TokenData = {
        "Token": Token,
    };

    return Axios.post(BASEURL + "api/Authentication/GetBalance", TokenData).then((res) => res.data);
}

export const GetCustomerInfo = (Token) => {

    var TokenData = {
        "Token": Token,
    };

    return Axios.post(BASEURL + "api/Authentication/GetCustomerInfo", TokenData).then((res) => res.data);
}

export const IsValidToken = (Token) => {

    var TokenData = {
        "Token": Token,
    };

    return Axios.post(BASEURL + "api/Authentication/IsValidToken", TokenData).then((res) => res.data);
}

export const GetCountries = (Token) => {

    var TokenData = {
        "Token": Token,
    };

    return Axios.post(BASEURL + "api/Authentication/GetCountries").then((res) => res.data);
}

export const GetStatesbyCountry = (CountryCode) => {

    var formData = {
        "CountryCode": CountryCode,
    };

    return Axios.post(BASEURL + "api/Authentication/GetStatesSelectWhere", formData).then((res) => res.data);
}

export const UpdateCustomerBillingInfo = (formData) => {

    return Axios.post(BASEURL + "api/Authentication/UpdateCustomerBillingInfo", formData).then((res) => res.data);
}

export const GetIPAddress = () => {

    return Axios.get("https://api.ipify.org/?format=json").then((res) => res.data);  
}

export const GetTransactionHistory = (formData) => {

    return Axios.post(BASEURL + "api/Transactions/TransactionsHistory", formData).then((res) => res.data);
}





