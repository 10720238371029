import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useParams } from "react-router-dom";
import clientInfo from '../login/ClientInfo';
import { PendingPayouts } from "../../services/PayoutService";

export const CustomerInfoHeader = () => {

    const navigate = useNavigate();
    const [arrayPendigPayouts, setPendingPayouts] = useState([]);
    useEffect(() => {
        var PayoutData = {
            "Token": localStorage.getItem('Token')
        };
        PendingPayouts(PayoutData).then(async function (response) {
            setPendingPayouts(response.Authentication);
        })

    }, [])

    return (
        <div>
            <div className="row">
                <div className="col-12 mt-3">
                    &nbsp;
                </div>
             </div>
            <div className="row">
                <div className="col-md-8 col-sm-12">
                    <div className="row px-md-4 py-md-3 mx-3 mb-3 alert border-orange alert alert-warning alert-white">
                        <div className="col-md-4 pr-5 text-center">
                            <div onClick={() => navigate('/payoutHistory/')} className="btn btn-squared-default-plain btn-white">
                                <FontAwesomeIcon icon={faEye} size="5x" beat  />
                                <br />
                                Track your
                                <br />
                                Withdrawal
                            </div>
                        </div>
                        <div className=" col-md-8 pr-5">
                                <b>Once your withdrawal request has been registered, you can easily track the status of it.<br/>  Also remember to supply personal ID if customer service requires it.</b>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 col-sm-12">
                    <div className="row  px-md-4 py-md-3 mx-3 mb-3 alert border-orange alert alert-warning alert-white">
                        <div className="col-12">
                            <div className="row mb-3">
                                <div className="col-6 text-left">
                                Account ID:
                                </div>
                                <div className="col-6 text-right">
                                    {clientInfo.CustomerPIN}
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-6 text-left">
                                    Current Balance:
                                </div>
                                <div className="col-6 text-right">
                                    {clientInfo.AvailableBalance}
                                </div>
                            </div>
                            <div className="row ">
                                <div className="col-6 text-left">
                                    Payouts:
                                </div>
                                <div className="col-6 text-right">
                                    {arrayPendigPayouts?.PendingPayouts} Pending
                                </div>
                            </div>
                        </div>
                            
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="alert alert-warning alert-white rounded justify-content-between border-width-4 px-4 py-3 mx-3 mb-3 border-orange">
                        <div className="icon"><FontAwesomeIcon icon={faTriangleExclamation} size="2x" /></div>
                        <div className="mx-5"><strong>Alert!</strong> Don't forget to save your data.</div>
                    </div>
                </div>
            </div>
           
        </div> 
        )
}