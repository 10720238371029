import React from "react";
import { useState } from 'react';
import Alerts from '../helpers/Alerts';
import clientInfo from '../login/ClientInfo';
import { useNavigate } from "react-router-dom";
import { GenerateToken, GetCustomerInfo, GetBalance, IsValidToken, GetIPAddress } from "../../services/AuthenticationService"
import BeatLoader from "react-spinners/BeatLoader"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faLock } from '@fortawesome/free-solid-svg-icons'
import Button from 'react-bootstrap/Button';
import { useLocation } from 'react-router-dom'

export const LoginComponent = () => {
    
    const navigate = useNavigate();
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const [isProcessing, setIsProcessing] = useState(false);

    if ((searchParams.get('Token') != null) || (searchParams.get('CustomerPIN') != null)) {        
        autoLogin(searchParams.get('CustomerPIN'), searchParams.get('Password'), searchParams.get('InstanceID'), searchParams.get('SkinID'), searchParams.get('BookID'),
            searchParams.get('BonusCode'), searchParams.get('Language'), searchParams.get('Token'), searchParams.get('UDF1'), searchParams.get('FixedAmount'));
    }  
   
    async function autoLogin(user, password, instanceID, SkinID, BookID, BonusCode, Language, Token, Udf1, FixedAmount) {
        localStorage.setItem('User', user);
        if (Token != null) {
            setIsProcessing(true);
            await IsValidToken(Token).then(async function (response) {

                if ((response.Authentication.Token != null)) {
                    localStorage.setItem('Udf1', Udf1);
                    localStorage.setItem('userLogin', user);
                    localStorage.setItem('FixedAmount', FixedAmount);
                    localStorage.setItem('Token', Token);
                    
                }
                else {
                    clientInfo.Error = false;
                    Alerts.ShowAlert('Error', 'Error getting token ' + response.Authentication.errordescription, 'error');

                }

            })
                .catch(function (error) {
                    clientInfo.Error = false;
                    Alerts.ShowAlert('Error', error.response.data.Authentication.errordescription, 'error');
                })
        }
        else {
            if (instanceID == null) {
                instanceID = "1";
            }
            if (SkinID == null) {
                SkinID = "1";
            }
            if (BookID == null) {
                BookID = "1";
            }
            await GetToken(user, password, instanceID, SkinID, BookID);
           
            if (clientInfo.Error) {
                localStorage.setItem('Udf1', Udf1);
                localStorage.setItem('userLogin', user);
                localStorage.setItem('FixedAmount', FixedAmount);
            }

        }
        if (clientInfo.Error) {
            setIsProcessing(false);
            navigate('/main/');
        }
    }

    async function GetToken(user, pass, instanceId, SkinId, BookId)  {
        await GenerateToken(user, pass, instanceId, SkinId, BookId).then(async function (response) {
            if (response.Authentication.AllFieldsComplete) {
                localStorage.setItem('Token', response.Authentication.Token);
                clientInfo.Error = true;
                await CustomerInfo();
                await Balance();
                await GetClientIPAddress();
             }
             else {
                clientInfo.Error = false;
                Alerts.ShowAlert('Error', 'Error getting token: ' + response.Authentication.errordescription, 'error');
                 
             }             
             
         })
            .catch(function (error) {
                clientInfo.Error = false;
                Alerts.ShowAlert('Error', 'Error getting token: ' + error.response.data.Authentication.errordescription, 'error');
        })
                
    }

    async function GetClientIPAddress() {
        await GetIPAddress().then(function (response) {
            localStorage.setItem('IPAddress', response.ip);
        })
         
    }

    async function CustomerInfo() {
        await GetCustomerInfo(localStorage.getItem('Token')).then(function (response) {
            
            if (response.Authentication.ErrorDescription != "Invalid input data") {
                clientInfo.funcMapCustomer(response);
                
            }
            else {
                clientInfo.Error = false;
                Alerts.ShowAlert('Error', 'Error getting customer info: ' + response.Authentication.ErrorDescription, 'error');
                
            }
            
            
        })
        .catch(function (error) {
            clientInfo.Error = false;
            Alerts.ShowAlert('Error', 'Error getting customer info: ' + error.response.data.Authentication.ErrorDescription, 'error');
        })        
    }

    async function Balance() {
        await GetBalance(localStorage.getItem('Token')).then(function (response) {
           
            if (response.Authentication.ErrorDescription == '') {
                clientInfo.funcMapBalance(response);
                
            }
            else {
                clientInfo.Error = false;
                Alerts.ShowAlert('Error', 'Error getting balance: ' + response.Authentication.ErrorDescription, 'error');
                
            }

        })
            .catch(function (error) {
                clientInfo.Error = false;
                Alerts.ShowAlert('Error', 'Error getting balance: ' + error.response.data.Authentication.ErrorDescription, 'error');
            })
        
    }

    async function Login(user, pass) {
        if ((user != '') || (pass != '')) {
            localStorage.setItem('User', user);
            await GetToken(user, pass, "1", "1", "1");

            if (clientInfo.Error) {                
                navigate('/main/');
            }
        }
        else {
            Alerts.ShowAlert('Information', 'Username and password required', 'info');
        }
        
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        var data = new FormData(event.target);
        let { username, password } = Object.fromEntries(data.entries());
        
        Login(username, password);
    }
   
    return (
        <div>
            {!isProcessing && <div className="limiter">
                <div className="container-login100 ">
                    <div className="form-group">
                        <form onSubmit={handleSubmit}>
                            <input className="input-field" type="text" placeholder="username" name="username" required />
                            <input className="input-field" type="password" placeholder="password" name="password"  required />

                            <input  type="submit" value="login" />
                        </form>
                    </div>
                </div>
            </div>
            }
            {isProcessing && <div className="loader-fullcontainer">
                <div className="loader-container">
                    <BeatLoader color="#36d7b7" size={60} />
                </div>
            </div>
            }
        </div>
      )
    }
  
  
