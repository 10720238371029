import clientInfo from '../login/ClientInfo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy, faNetworkWired } from '@fortawesome/free-solid-svg-icons'
import { useEffect, useState, useRef  } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import Alerts from '../helpers/Alerts';
import { IsValidToken } from "../../services/AuthenticationService";
import { CryptoDeposit } from "../../services/DepositService";
import BeatLoader from "react-spinners/BeatLoader";
import { onlyNumbers, BonusString } from '../helpers/common';
import { isMobile } from 'react-device-detect';
import Bonus from '../Bonus';


export const CryptoMethods = () => {
    let INSTANCEID = process.env.REACT_APP_InstanceID;
    let MAXLIMIT = process.env.REACT_APP_MaxLimit;
    const [isProcessing, setIsProcessing] = useState(false);
    const { id } = useParams();
    const [arrayRegularDeposits, setRegularDeposits] = useState(JSON.parse(localStorage.getItem('ArrayRegularMethods')).filter(function (element) { return element.DepositID == id; })[0]);
    const navigate = useNavigate();
    const [showComponent, setshowComponent] = useState(false);
    const [CryptoResponse, setCryptoResponse] = useState('');
    const [cryptoNetwork, setCryptoNetwork] = useState('bitcoin');
    const [OriginalAmount, setOriginalAmount] = useState(0);
    const scrollRef = useRef('qrcode');
   
    useEffect(() => {
        IsValidToken(localStorage.getItem('Token')).then()
            .catch(function (error) {
                navigate('/expired/');
            })

        if (arrayRegularDeposits == undefined) {
            navigate('/deposits/');
        }
        switch (id.toLowerCase()) {
            case 'bitcoin':
                setCryptoNetwork('bitcoin');
                break;
            case 'litecoin':
                setCryptoNetwork('litecoin');
                break;
            case 'bitcoincash':
                setCryptoNetwork('bitcoincash');
                break;
            case 'bnb':
                setCryptoNetwork('bsc-bep20');
                break;
            default:
                setCryptoNetwork('ethereum-erc20');
                break;
        }
        
    }, [])


    async function doCryptoDeposit(Amount) {
        await CryptoDeposit(localStorage.getItem('Token'), arrayRegularDeposits.DepositName, INSTANCEID, Amount, localStorage.getItem('CurrencyCode'), '', '', '-1', clientInfo.IPAddress, BonusString(JSON.parse(localStorage.getItem('ArrayBonus')))).then(function (response) {
            setCryptoResponse(response.Authentication);
            
            if (isMobile) {
                Alerts.ShowAlertFullScreen('Deposit Status', response.Authentication.HtmlResponse + ' <p>TransactionID: ' + response.Authentication.TransactionID + ' </p>' + ' <p>Send ' + response.Authentication.Amount + '  to this address: </p>' + ' <p>' + response.Authentication.Descriptor + '</p>', 'info', 'qrcode');
            }
            
        })
            .catch(function (error) {
                
                Alerts.ShowAlert('Error', 'Error making deposit', 'error');
            })

        
    }
    const handleSubmit2 = async (event) => {
        event.preventDefault();
        console.log(BonusString(JSON.parse(localStorage.getItem('ArrayBonus'))));
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log(BonusString(JSON.parse(localStorage.getItem('ArrayBonus'))));
        if (!isProcessing) {
            var data = new FormData(event.target);
            let { amount } = Object.fromEntries(data.entries());
            if (amount != '') {
                setIsProcessing(true);
                setOriginalAmount(amount);
                await doCryptoDeposit(amount);
                setIsProcessing(false);
                setshowComponent(true);
                
            }
            else {
                Alerts.ShowAlert('Warning', 'Amount required', 'warning');
            }

        }
    }
   

    return (
        <div >
            <form onSubmit={handleSubmit}>
                <div className="row m-4">
                    <span className="icon-single-left txt-primary">
                        <img style={{ width: "100px", height: "100px" }} src={"/svg/" + arrayRegularDeposits?.ImageName + ".svg"} /> Deposit using {arrayRegularDeposits?.DepositID}
                    </span>
                </div>
                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <div className="row">
                            <div className="col-md-12 mb-3">
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-fa d-none d-md-block">
                                            <FontAwesomeIcon icon={faNetworkWired} />
                                        </span>
                                    </div>
                                    <select className="form-select selectpicker" aria-label="Select Network" value={cryptoNetwork} style={{textAlignLast: "center"}} disabled>
                                    <option >Select Network</option>
                                    <option value="bitcoin">Bitcoin</option>
                                    <option value="litecoin">Litecoin</option>
                                    <option value="bitcoincash">Bitcoincash</option>
                                    <option value="ethereum-erc20">Ethereum-Erc20</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-12 m3">
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-currency-cards d-none d-md-block" id="lblCurrency">
                                            <select className="form-control selectcurrency" disabled name="ccCurrency" required value={clientInfo.CurrencyCode}>
                                                <option value="" className="">Currency</option>
                                                <option value="USD">USD</option>
                                            </select>
                                        </span>
                                    </div>
                                    <input type="number" onKeyPress={(event) => onlyNumbers(event)}  aria-label="Amount (to the nearest dollar)" className="form-control" id="amount" aria-describedby="lblCurrency" placeholder="Amount" max={arrayRegularDeposits?.MaxDeposit == 0 ? MAXLIMIT : arrayRegularDeposits?.MaxDeposit} min={arrayRegularDeposits?.MinDeposit == 0 ? 1 : arrayRegularDeposits?.MinDeposit} name="amount" required=""/>
                                </div>

                                <div className="d-flex flex-row ml-2 limit-text-center mb-4 ">
                                    Deposit Limits: Minimum {arrayRegularDeposits?.MinDeposit == 0 ? 1 : arrayRegularDeposits?.MinDeposit} USD Maximum {arrayRegularDeposits?.MaxDeposit == 0 ? 'No Limit' : arrayRegularDeposits?.MaxDeposit + ' USD'}
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    {showComponent && <div id="qrcode" name="qrcode" className="col-md-6 col-sm-12">
                        <div className="row">
                            <div className="col-md-4 mb-3" dangerouslySetInnerHTML={{ __html: CryptoResponse.HtmlResponse }}>
                                
                            </div>
                            <div className="col-md-8 mb-3">
                                TransactionID: <b className="crypto-address">{CryptoResponse.TransactionID}</b>
                                <br />
                                Send<span className="crypto-address">&nbsp;&nbsp; {CryptoResponse.Amount == null ? OriginalAmount + ' USD' : CryptoResponse.Amount}</span>&nbsp;&nbsp;<FontAwesomeIcon icon={faCopy} onClick={() => { navigator.clipboard.writeText(CryptoResponse.Amount == null ? OriginalAmount + ' USD' : CryptoResponse.Amount) }} style={{ cursor: 'pointer' }} />  to this address:
                                <br /><br />
                                <span className="crypto-address text-center">{CryptoResponse.Descriptor} &nbsp;<FontAwesomeIcon icon={faCopy} onClick={() => { navigator.clipboard.writeText(CryptoResponse.Descriptor) }} style={{ cursor: 'pointer' }} /></span>
                                </div>
                        </div>
                    </div>}
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="alert alert-danger text-center" role="alert">
                            Only Bitcoin can be sent to the generated address from the selected network.  Deposits of other crypto currencies or networks will be lost.
                        </div>
                    </div>
                </div>
                <Bonus />
                <div className="row">
                    <div className="col-md-12 col-sm-12 col-xs-12 " >
                    
                    </div>
                    <div className="col-md-12 mb-3-small mt-4">
                        <button type="button" className="btn btn-secondary btn-gray btn-back-order mb-4 d-none d-md-block" onClick={() => navigate('/deposits/')}>BACK</button>
                        <button type="submit" className="btn btn-deposit btn-deposit-order mb-4 submit">DEPOSIT</button>
                    </div>
                </div>  


            </form>
            <div>
                {isProcessing && <div className="loader-fullcontainer">
                    <div className="loader-container">
                        <BeatLoader color="#36d7b7" size={60} />
                    </div>
                </div>}
            </div>
        </div>
    );
}