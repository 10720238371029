const clientInfo = () => { }

export default clientInfo;

clientInfo.languaje = "";
clientInfo.Password = "";
clientInfo.Error = true;
clientInfo.Udf1 = "";
clientInfo.IPAddress = "";


clientInfo.funcMapCustomer = async (data) => {
    localStorage.setItem('Name', await data.Authentication.Name);
    localStorage.setItem('LastName', await data.Authentication.LastName);
}

clientInfo.funcMapBalance = async (data) => {
    localStorage.setItem('AvailableBalance', await data.Authentication.AvailableBalance);
    localStorage.setItem('CurrencyCode', await data.Authentication.CurrencyCode);
}

clientInfo.Name = localStorage.getItem('Name');
clientInfo.LastName = localStorage.getItem('LastName');

clientInfo.CurrencyCode = localStorage.getItem('CurrencyCode');
clientInfo.AvailableBalance = localStorage.getItem('AvailableBalance');
clientInfo.IPAddress = localStorage.getItem('IPAddress');

clientInfo.CustomerPIN = localStorage.getItem('User');