import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import clientInfo from '../login/ClientInfo';
import Alerts from '../helpers/Alerts';
import { IsValidToken } from "../../services/AuthenticationService";
import { GetPayoutsLimits } from "../../services/PayoutService"
import Loading from '../helpers/Loader';

export const PayoutMethods = () => {
    let MAXLIMIT = process.env.REACT_APP_MaxLimit;
    const [isLoading, setIsLoading] = useState(true);
    const [arrayPayouts, setArrayPayouts] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {

        IsValidToken(localStorage.getItem('Token')).then(async function (response) {
            await PayoutsLimits();
        }

        ).catch(function (error) {
            navigate('/expired/');
        })

    }, []);


    async function PayoutsLimits() {
        await GetPayoutsLimits(localStorage.getItem('Token'), clientInfo.CurrencyCode, clientInfo.AvailableBalance).then(async function (response) {

            var i = 0;
            const filtered = response.Authentication.map(item => {

                const {
                    IconURL,
                    ImageName,
                    MaxPayout,
                    MinPayout,
                    PaymentType,
                    PayoutID,
                    PayoutName,
                    ProcessorID
                } = item;
                if (item.PaymentType.toLowerCase() == "credit card" ||
                    item.PaymentType.toLowerCase() == "crypto" ||
                    item.PaymentType.toLowerCase() == "money gram" ||
                    item.PaymentType.toLowerCase() == "generic"
                ) {
                    return {
                        Key: i++,
                        IconURL,
                        ImageName,
                        MaxPayout,
                        MinPayout,
                        PaymentType,
                        PayoutID,
                        PayoutName,
                        ProcessorID
                    };
                }
            });
            await setArrayPayouts(filtered.filter(function (element) { return element !== undefined; }));
            setIsLoading(false);
        }).catch(function (error) {
            setIsLoading(false);
            clientInfo.Error = false;
            Alerts.ShowAlert('Error', 'Error getting payout limits info: ' + error.response.data.Authentication.ErrorDescription, 'error');


        })
    }

    function GetFormURL(Key) {
        if (arrayPayouts[Key].PaymentType.toLowerCase() == "credit card")
            return "/payouts/creditcard/"
        else if (arrayPayouts[Key].PaymentType.toLowerCase() == "crypto")
            return "/payouts/crypto/" + arrayPayouts[Key].PayoutID
        else if (arrayPayouts[Key].PaymentType.toLowerCase() == "money gram")
            return "/payouts/moneygram/"
        else if (arrayPayouts[Key].PaymentType.toLowerCase() == "generic") {
            return "/payouts/generic/" + arrayPayouts[Key].ProcessorID
        }
        else
            return "";
    }

    if (isLoading) {
        return <Loading />;
    }

    const handleMethodSelect = (name) => {
        navigate(`/deposits/${name}`);
    }

    localStorage.setItem('arrayPayoutsMethods', JSON.stringify(arrayPayouts));

    return (
        <div className="mt-3">
            <div className="title">
                <div className="container">
                    <h4 className="txt-primary payout-title-color mb-4">Payout methods</h4>
                </div>
            </div>

            <div className="row">
                {arrayPayouts.map((item) => (
                    <div className="col-xxl-4 col-md-4 col-sm-6 col-xs-6">
                        <a key={item.Key} href={GetFormURL(item.Key)} className="box-payout  mb-4">
                            <div className="row">
                                <div className="col-md-3 col-sm-12">
                                    <span className="icon-single">
                                        {(item.PaymentType === 'Generic') ?
                                            <img alt="" src={"data:image/svg+xml;base64," + item.ImageName} />
                                            :
                                            <img alt="" src={"/svg/" + item.ImageName + ".svg"} />
                                        }
                                    </span>
                                </div>
                                <div className="col-md-8 col-sm-12 text-center limit-text">
                                    <span>Min:${item.MinPayout == 0 ? 1 : item.MinPayout} - Max:{item.MaxPayout == 0 ? 'No Limit' : '$' + item.MaxPayout}</span>
                                </div>
                                <div className="col-md-3 limit-text d-none d-md-block ">

                                </div>
                            </div>
                        </a>
                    </div>
                ))}
                <div className="col-xxl-4 col-md-4 col-sm-12 col-xs-12">
                    <div onClick={() => handleMethodSelect('')} className="box-deposit  othermethod-color mb-4 text-center text-wrap height-68" >
                        <span className="othermethod-text text-center ">USE OTHER DEPOSIT METHODS</span>
                    </div>
                </div>
               
            </div>
        </div>
    )
}