import React from 'react';
import BeatLoader from "react-spinners/BeatLoader"

function Loading() {
    return (
        <div className="loader-container">
            <BeatLoader color="#36d7b7" size={45}/>
        </div>
    );
}

export default Loading;